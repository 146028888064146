import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import RichText from 'components/rich-text';
import LinkButton from 'components/link-button';
import cn from 'classnames';

const sizes = {
  default: 'default',
  small: 'small',
};

const SimpleListTeaser = ({ image, heading, introHtml, link, size }) => {
  const _size = size || sizes.default;
  return (
    <a
      className="simple-list-teaser"
      href={link.url}
      target={link.openInNewTab ? '_blank' : null}
      rel={link.openInNewTab ? 'nofollow noreferrer' : null}
    >
      {image && (
        <Image
          className="simple-list-teaser__image image_size_simple-list-teaser"
          {...image}
        />
      )}
      <div
        className={cn('simple-list-teaser__row', {
          [`simple-list-teaser__row_${_size}`]: _size,
        })}
      >
        <div
          className={cn('simple-list-teaser__column', {
            [`simple-list-teaser__column_${_size}`]: _size,
          })}
        >
          {heading && (
            <h2 className="simple-list-teaser__heading">{heading}</h2>
          )}
          {introHtml && (
            <RichText
              className="rich-text_in-simple-list-teaser"
              text={introHtml}
            />
          )}
        </div>
        {link && link.text && (
          <span className="simple-list-teaser__more-link">{link.text}</span>
        )}
      </div>
    </a>
  );
};

SimpleListTeaser.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  heading: PropTypes.string,
  introHtml: PropTypes.string,
  link: PropTypes.exact(LinkButton.propTypes),
  size: PropTypes.oneOf(Object.values(sizes)),
};

SimpleListTeaser.viewModelMeta = {
  size: 'ignore',
};

export default SimpleListTeaser;
