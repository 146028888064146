import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import RichText from 'components/rich-text';
import cn from 'classnames';

const ImageWithCaption = ({
  image,
  caption,
  imageSizeClassName,
  className,
}) => {
  return (
    <div className={cn('image-with-caption', className)}>
      {image && (
        <Image
          className={`image-with-caption__image ${
            imageSizeClassName
              ? imageSizeClassName
              : 'image_size_image-with-caption'
          }`}
          {...image}
        />
      )}
      {caption && (
        <RichText
          className="image-with-caption__caption rich-text_in-image-with-caption-block"
          text={caption}
        />
      )}
    </div>
  );
};

ImageWithCaption.propTypes = {
  image: PropTypes.exact(Image.propTypes).isRequired,
  caption: PropTypes.string,
  className: PropTypes.string,
  imageSizeClassName: PropTypes.string,
};

ImageWithCaption.viewModelMeta = {
  className: 'ignore',
  imageSizeClassName: 'ignore',
};

export default ImageWithCaption;
