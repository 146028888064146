import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import Image from 'components/image';
import useEvent from 'hooks/use-event';
import FocusTrap from 'focus-trap-react';

const Modal = ({
  isOpened = false,
  closeLabel,
  onClose,
  children,
  logo,
  titleAriaLabel,
}) => {
  useEvent('keydown', event => {
    if (event.key === 'Escape') {
      onClose && onClose();
    }
  });

  return (
    isOpened && (
      <FocusTrap active={isOpened}>
        <div
          className="modal"
          role="dialog"
          aria-modal="true"
          aria-label={titleAriaLabel}
        >
          <div className="modal__container">
            <div className="modal__header">
              {logo && <Image className="modal__logo" {...logo} />}
              <button
                className="modal__close-button"
                aria-label={closeLabel}
                onClick={onClose}
              >
                {closeLabel && (
                  <span className="modal__close-button-label">
                    {closeLabel}
                  </span>
                )}
                <Icon name="close" />
              </button>
            </div>
            {children}
          </div>
        </div>
      </FocusTrap>
    )
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  logo: PropTypes.exact(Image.propTypes),
  isOpened: PropTypes.bool,
  closeLabel: PropTypes.string,
  onClose: PropTypes.func,
  titleAriaLabel: PropTypes.string,
};

Modal.viewModelMeta = 'ignore';

export default Modal;
