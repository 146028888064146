import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Answer = ({
  text,
  isCorrect,
  onChange,
  checked,
  showResults,
  name,
  isMultipleChoice,
}) => {
  return (
    <div>
      <label className="questionnaire__answer-label">
        <input
          onChange={onChange}
          checked={checked ? checked : false}
          type={isMultipleChoice ? 'checkbox' : 'radio'}
          name={name}
          disabled={showResults ? true : false}
          value={text || ''}
        ></input>

        <span
          className={cn('questionnaire__answer-checkmark', {
            'questionnaire__answer-checkmark questionnaire__answer-checkmark--radio':
              !isMultipleChoice,
          })}
        ></span>

        <div
          className={cn('questionnaire__answer-text', {
            'questionnaire__answer-text--is-correct':
              showResults && checked && isCorrect,
            'questionnaire__answer-text--is-wrong':
              showResults && checked && !isCorrect,
          })}
        >
          {text}
        </div>
      </label>
    </div>
  );
};

Answer.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string,
  isCorrect: PropTypes.bool,
  showResults: PropTypes.bool,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  isMultipleChoice: PropTypes.bool,
};

Answer.viewModelMeta = {
  showResults: 'ignore',
  checked: 'ignore',
  name: 'ignore',
  isMultipleChoice: 'ignore',
};

export default Answer;
