import React from 'react';
import PropTypes from 'prop-types';
import * as Components from '../../app.components';

const ContentArea = ({ blocks }) => {
  return blocks ? (
    <div className="content-area">
      {blocks.map((block, index) => {
        const Block = Components[block.componentName];
        if (!Block) {
          // eslint-disable-next-line no-console
          console.error(`Components.${block.componentName} is not defined`);
          return null;
        }
        return (
          <div key={block.componentName + index}>
            <Block {...block} />
          </div>
        );
      })}
    </div>
  ) : null;
};

ContentArea.propTypes = {
  blocks: PropTypes.array,
};

ContentArea.viewModelMeta = 'ignore';

export default ContentArea;
