import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import RichText from 'components/rich-text';
import Link from 'components/link';

const GettingStartedTeaser = ({ image, heading, introHtml, link }) => {
  return (
    <a
      className="getting-started-teaser"
      href={link.url}
      target={link.openInNewTab ? '_blank' : null}
      tabIndex={link.tabindex}
      aria-current={link.ariaCurrent ? link.ariaCurrent : null}
      rel={link.openInNewTab ? 'nofollow noreferrer' : null}
    >
      {image && <Image className="getting-started-teaser__image" {...image} />}
      {heading && (
        <h2 className="getting-started-teaser__heading">{heading}</h2>
      )}
      {introHtml && (
        <RichText
          className="rich-text_in-getting-started-teaser"
          text={introHtml}
        />
      )}
      {link && link.text && (
        <span className="getting-started-teaser__more-link">{link.text}</span>
      )}
    </a>
  );
};

GettingStartedTeaser.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  heading: PropTypes.string,
  introHtml: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
};

export default GettingStartedTeaser;
